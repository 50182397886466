import React, { useState } from "react";
import { Link } from "react-scroll";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Logo from "./assests/Logo.jpg"
import { auth } from "../firebase";

const Navbar = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleClick = () => {
    navigate("/user");
  };

  return (
    <nav className="absolute top-0 left-0 right-0 z-50">
      <div className="container mx-auto px-6 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <img src={Logo} alt="GoldenBridge" className="h-12 w-12 rounded-md" />
            <span className="text-2xl font-bold text-white">Golden<span className="text-[#5CD6C0]">Bridge</span></span>
          </div>
          
          <div className="hidden md:flex items-center space-x-8">
            <Link to="modelPortfolio" smooth={true} duration={500} className="text-white hover:text-[#5CD6C0] transition-colors duration-300">
              Model Portfolios
            </Link>
            <Link to="subscriptionSection" smooth={true} duration={800} className="text-white hover:text-[#5CD6C0] transition-colors duration-300">
              Stock Tips
            </Link>
            <Link to="aboutSection" smooth={true} duration={1000} className="text-white hover:text-[#5CD6C0] transition-colors duration-300">
              About Advisor
            </Link>
            <Link to="faqSection" smooth={true} duration={1000} className="text-white hover:text-[#5CD6C0] transition-colors duration-300">
              FAQ
            </Link>
            <button
              className="bg-[#5CD6C0] text-[#0F0F0F] px-6 py-2 rounded-md hover:bg-[#4bc0ac] transition-colors duration-300"
              onClick={handleClick}
            >
              {userEmail ? "Dashboard" : "How to Invest"}
            </button>
          </div>

          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none"
              aria-label="toggle menu"
            >
              {!isOpen ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>

        {isOpen && (
          <div className="md:hidden mt-4">
            <div className="flex flex-col space-y-4">
              <Link to="modelPortfolio" smooth={true} duration={500} className="text-white hover:text-[#5CD6C0] transition-colors duration-300" onClick={toggleMenu}>
                Model Portfolios
              </Link>
              <Link to="subscriptionSection" smooth={true} duration={800} className="text-white hover:text-[#5CD6C0] transition-colors duration-300" onClick={toggleMenu}>
                Stock Tips
              </Link>
              <Link to="aboutSection" smooth={true} duration={1000} className="text-white hover:text-[#5CD6C0] transition-colors duration-300" onClick={toggleMenu}>
                About Advisor
              </Link>
              <Link to="faqSection" smooth={true} duration={1000} className="text-white hover:text-[#5CD6C0] transition-colors duration-300" onClick={toggleMenu}>
                FAQ
              </Link>
              <button
                className="bg-[#5CD6C0] text-[#0F0F0F] px-6 py-2 rounded-md hover:bg-[#4bc0ac] transition-colors duration-300"
                onClick={() => {
                  handleClick();
                  toggleMenu();
                }}
              >
                {userEmail ? "Dashboard" : "How to Invest"}
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

