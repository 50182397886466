import React, { useState } from "react"
import Bg from "./assests/HeroSection/headerBG.svg"
import Logo from "./assests/Logo.jpg"
import rightSection from './assests/HeroSection/rightSection.png'
import { Link } from "react-scroll";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";

const HeaderSection = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleClick = () => {
    navigate("/user");
  };

  return (
    <div className="relative bg-[#0F0F0F] h-full sm:h-[530px]">
      {/* Background */}
      <img
        src={Bg}
        alt=""
        className="absolute inset-0 w-full h-full object-cover opacity-70"
      />
      {/* Content wrapper */}
      <div className="relative z-10">
        {/* Navbar */}
        <nav className="absolute top-0 left-0 right-0 z-50 border-b border-gray-700/50">
          <div className="max-w-[1400px] mx-auto px-6 lg:px-8">
            <div className="flex items-center justify-between py-4">
              <div className="flex items-center space-x-2">
                <img src={Logo} alt="GoldenBridge" className="h-12 w-12 rounded-md" />
                <span className="text-2xl font-bold text-white">Golden<span className="text-[#5CD6C0]">Bridge</span></span>
              </div>
              
              <div className="hidden md:flex items-center space-x-12">
                <Link to="modelPortfolio" smooth={true} duration={500} className="text-white hover:text-[#5CD6C0] transition-colors duration-300">
                  Model Portfolios
                </Link>
                <Link to="subscriptionSection" smooth={true} duration={800} className="text-white hover:text-[#5CD6C0] transition-colors duration-300">
                  Stock Tips
                </Link>
                <Link to="aboutSection" smooth={true} duration={1000} className="text-white hover:text-[#5CD6C0] transition-colors duration-300">
                  About Advisor
                </Link>
                <Link to="faqSection" smooth={true} duration={1000} className="text-white hover:text-[#5CD6C0] transition-colors duration-300">
                  FAQ
                </Link>
                <button
                  className="bg-[#5CD6C0] text-[#0F0F0F] px-6 py-2 rounded-md hover:bg-[#4bc0ac] transition-colors duration-300 font-medium"
                  onClick={handleClick}
                >
                  {userEmail ? "Dashboard" : "How to Invest"}
                </button>
              </div>

              <div className="md:hidden">
                <button
                  onClick={toggleMenu}
                  className="text-white focus:outline-none"
                  aria-label="toggle menu"
                >
                  {!isOpen ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  )}
                </button>
              </div>
            </div>

            {isOpen && (
              <div className="md:hidden py-4 bg-[#0A1F34]">
                <div className="flex flex-col space-y-4">
                  <Link to="modelPortfolio" smooth={true} duration={500} className="text-white hover:text-[#5CD6C0] transition-colors duration-300 px-4" onClick={toggleMenu}>
                    Model Portfolios
                  </Link>
                  <Link to="subscriptionSection" smooth={true} duration={800} className="text-white hover:text-[#5CD6C0] transition-colors duration-300 px-4" onClick={toggleMenu}>
                    Stock Tips
                  </Link>
                  <Link to="aboutSection" smooth={true} duration={1000} className="text-white hover:text-[#5CD6C0] transition-colors duration-300 px-4" onClick={toggleMenu}>
                    About Advisor
                  </Link>
                  <Link to="faqSection" smooth={true} duration={1000} className="text-white hover:text-[#5CD6C0] transition-colors duration-300 px-4" onClick={toggleMenu}>
                    FAQ
                  </Link>
                  <div className="px-4">
                    <button
                      className="w-full bg-[#5CD6C0] text-[#0F0F0F] px-6 py-2 rounded-md hover:bg-[#4bc0ac] transition-colors duration-300 font-medium"
                      onClick={() => {
                        handleClick();
                        toggleMenu();
                      }}
                    >
                      {userEmail ? "Dashboard" : "How to Invest"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </nav>

        {/* Hero Section */}
        <div className="mx-auto max-w-[1400px] px-6 lg:px-8 pt-32 pb-20 md:py-32">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div className="space-y-6">
              <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white leading-tight">
                Maximize Your Financial Potential with{" "}
                <span className="text-[#5CD6C0]">GoldenBridge</span>
              </h1>
              
              <p className="text-gray-300 text-base sm:text-lg">
                Transform your wealth with GoldenBridge - where expert human
                insights are amplified with effective and efficient automations.
              </p>

              <div className="flex flex-wrap gap-4">
                <button className="w-full sm:w-auto px-8 py-3 border-2 border-white text-white rounded-md hover:bg-white hover:text-[#0F0F0F] transition-colors font-medium">
                  Explore More
                </button>
                <button className="w-full sm:w-auto px-8 py-3 bg-[#5CD6C0] text-[#0F0F0F] rounded-md hover:bg-[#4bc0ac] transition-colors font-medium">
                  Get Started
                </button>
              </div>
            </div>

            <div className="hidden lg:block">
              <div className="relative h-[400px] w-full">
                <img 
                  src={rightSection} 
                  alt="Financial Analytics Illustration"
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderSection
