"use client";

import React, { useEffect, useState, useRef } from "react";
import { CircleCheck } from "lucide-react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Bg from "./assests/Subscritionsection/bg.png";
import Check from "./assests/Subscritionsection/check.png";
import Tag from "./assests/Subscritionsection/tag.png";

const SubscriptionSection = ({
  allPricingPlan = [],
  handlePricingCardClick,
}) => {
  const [pricingPlans, setPricingPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const headerRefs = useRef([]);
  const rowRefs = useRef([]);
  
  useEffect(() => {
    if (allPricingPlan.length > 0) {
      setPricingPlans(allPricingPlan);
      setIsLoading(false);
      setShowSkeleton(false); // Stop showing the skeleton when data is available
    } else {
      const timer = setTimeout(() => {
        setShowSkeleton(false); // Hide skeleton after 3 seconds
      }, 3000);

      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, [allPricingPlan]);

  useEffect(() => {
    if (!isLoading) {
      adjustCardHeights();
    }
  }, [isLoading, pricingPlans]);

  const adjustCardHeights = () => {
    const rows = [];
    let currentRow = [];
    rowRefs.current.forEach((card, index) => {
      if (card) {
        currentRow.push(card);
        if ((index + 1) % 3 === 0 || index === rowRefs.current.length - 1) {
          rows.push(currentRow);
          currentRow = [];
        }
      }
    });

    rows.forEach((row) => {
      const descriptionContainers = row.map((card) =>
        card.querySelector(".description-container")
      );
      const hasDescription = descriptionContainers.some(
        (container) => container?.textContent?.trim().length > 0
      );

      if (hasDescription) {
        const maxHeight = Math.max(
          ...descriptionContainers.map(
            (container) => container?.scrollHeight || 0
          )
        );
        descriptionContainers.forEach((container) => {
          if (container) {
            container.style.height = `${maxHeight}px`;
          }
        });
      } else {
        descriptionContainers.forEach((container) => {
          if (container) {
            container.style.height = "auto";
          }
        });
      }
    });
  };

  // const [activeCycle, setActiveCycle] = useState('Monthly')

  // const billingCycles = ['Monthly', 'Quarterly', 'Half-yearly', 'Yearly']

  // const pricingCards = [
  //   {
  //     name: "STARTER",
  //     price: "499",
  //     features: Array(5).fill("Lorem ipsum dolor sit amet consectetur")
  //   },
  //   {
  //     name: "PRO",
  //     price: "999",
  //     isPopular: true,
  //     features: Array(5).fill("Lorem ipsum dolor sit amet consectetur")
  //   },
  //   {
  //     name: "PLATINUM",
  //     price: "19,999",
  //     features: Array(5).fill("Lorem ipsum dolor sit amet consectetur")
  //   }
  // ]

  const SkeletonCard = () => (
    <div className="w-full sm:w-[calc(50%-12px)] lg:w-[calc(33.333%-16px)] max-w-[350px] relative font-poppins min-h-[200px] rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4">
      <div className="flex items-start pl-7 pt-2 pb-7 md:items-center">
        <div className="overflow-hidden rounded-lg">
          <Skeleton width={128} height={25} />
          <Skeleton width={100} height={30} />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-[80%] border-t-[1px] border-b-[1px] border-[#000000]/10"></div>
      </div>
      <div className="flex flex-col items-center pt-7 p-4 space-y-4 w-90">
        <div className="font-poppins text-[12.5px] min-h-[220px] flex flex-col items-left justify-start space-y-2 text-[#666666]">
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
        </div>
      </div>
      <div className="p-4">
        <div className="flex justify-center align">
          <Skeleton width={280} height={44} />
        </div>
      </div>
    </div>
  );

  if (!showSkeleton && allPricingPlan.length === 0) {
    return null; // Return null after 3 seconds if no data
  }
  return (
    <div
      className="pt-12 pb-32"
      style={{ backgroundImage: `url(${Bg})` }}
      id="subscriptionSection"
    >
      <h1 className="text-[28px] sm:text-3xl md:text-4xl font-bold text-white text-center mt-4 sm:mt-6 md:mt-8 px-4 leading-tight">
        Stock Recommendations To Elevate Your Portfolio
      </h1>

      <div className="container mx-auto px-4 sm:px-20 pt-10 md:pt-14 lg:pt-14">
        <div className="flex flex-wrap justify-center gap-6">
          {isLoading
            ? Array(3)
                .fill(0)
                .map((_, i) => <SkeletonCard key={i} />)
            : pricingPlans.map((ele, i) => (
                <div
                  key={i}
                  ref={(el) => (rowRefs.current[i] = el)}
                  className="w-full sm:w-[calc(50%-12px)] lg:w-[calc(33.333%-16px)] max-w-[350px] relative font-poppins rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4 cursor-pointer"
                  onClick={
                    ele?.subscription
                      ? undefined
                      : () => handlePricingCardClick(ele)
                  }
                >
                  <div className="flex items-start pl-7 pt-2 pb-7 md:items-center">
                    <div className="overflow-hidden rounded-lg">
                      <h2 className="font-poppins w-[128.1px] text-[22px] font-bold leading-[35px] text-left text-[#333333]">
                        {ele?.name}
                      </h2>
                      <div className="flex items-baseline">
                        <h2 className="font-poppins text-[40px] font-bold text-[#333333] leading-none">
                          ₹
                          {ele?.amount ||
                            (ele?.pricing &&
                              (ele.pricing.monthly ||
                                ele.pricing.quarterly ||
                                ele.pricing.halfYearly ||
                                ele.pricing.yearly)) ||
                            "N/A"}
                        </h2>
                        {ele?.frequency?.length !== 0 && (
                          <p className="text-[16px] text-[#95989C] font-normal ml-1">
                            /{ele?.frequency}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start items-start">
                    {" "}
                    {/* Update 1 */}
                    <div className="w-[80%] border-t-[1px] border-b-[1px] border-[#000000]/10"></div>
                  </div>
                  <div className="flex flex-col items-start pt-7 p-4 space-y-4 w-90">
                    {" "}
                    {/* Update 2 */}
                    <div className="font-poppins text-[12.5px] flex flex-row items-start space-x-2 text-[#666666] description-container">
                      <CircleCheck
                        size={20}
                        className="text-white fill-[#16826C] shrink-0"
                      />
                      <span className="text-sm font-poppins text-left text-gray-600">
                        {ele?.description || "No description available"}
                      </span>
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="flex justify-start align">
                      {" "}
                      {/* Update 3 */}
                      <button className="w-[17.5rem] h-11 py-2 px-1 rounded-md bg-white border-black border-[2px] text-black text-base font-semibold hover:bg-black hover:text-white hover:border-black transition-all duration-150 ease-linear">
                        {ele?.subscription ? "Subscribed" : "Subscribe Now"}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>

    // <div
    //   className=" bg-cover  bg-no-repeat font-poppins mt-20 mb-20"
    //   style={{ backgroundImage: `url(${Bg})` }} id="subscriptionSection"
    // >
    //   <div className="px-4 py-5 sm:py-20">
    //     <h1 className="text-2xl font-bold text-white text-center mb-8">
    //       Stock Tips to Elevate Your Portfolio
    //     </h1>

    //     {/* Billing Cycle Tabs */}
    //     <div className="flex flex-wrap justify-center space-x-2 sm:space-x-4 mb-8 relative">
    //   <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[100%] sm:w-[40%] h-px bg-white opacity-40" />
    //   {billingCycles.map((cycle) => (
    //     <button
    //       key={cycle}
    //       onClick={() => setActiveCycle(cycle)}
    //       className={`relative px-2 py-1 text-sm transition-colors ${
    //         activeCycle === cycle
    //           ? 'text-white'
    //           : 'text-gray-400 hover:text-gray-300'
    //       }`}
    //     >
    //       {cycle}
    //       {activeCycle === cycle && (
    //         <div className="absolute bottom-0 left-0 w-full h-px bg-white z-10" />
    //       )}
    //     </button>
    //   ))}
    // </div>

    //     {/* Pricing Cards */}
    //     <div className="flex flex-col sm:flex-row justify-center items-center sm:items-stretch gap-8">
    //       {pricingCards.map((card) => (
    //         <div
    //           key={card.name}
    //           className="relative bg-white rounded-2xl p-6 shadow-lg w-full sm:w-[328.64px] h-[370px] flex flex-col justify-between mb-8 sm:mb-0"
    //         >
    //           {card.isPopular && (
    //             <img
    //               src={Tag}
    //               alt="Most Popular"
    //               className="absolute -top-5 sm:-top-7 -right-5 sm:-right-7 w-32 h-30 sm:w-44 sm:h-auto transform rotate-20"
    //             />
    //           )}

    //           <div>
    //             <h3 className="text-lg font-bold text-gray-900 mb-2">{card.name}</h3>
    //             <div className="flex items-baseline">
    //               <span className="text-2xl font-bold">₹{card.price}</span>
    //               <span className="text-gray-500 ml-2 text-sm">/month</span>
    //             </div>
    //             <div className="w-full h-px bg-gray-200 my-6"></div>

    //           </div>

    //           <div className="space-y-3 flex-grow ">
    //             {card.features.map((feature, index) => (
    //               <div key={index} className="flex items-start gap-2">
    //                 <img src={Check} alt="Check" className="h-4 w-4 shrink-0 mt-1" />
    //                 <span className="text-xs text-gray-600">{feature}</span>
    //               </div>
    //             ))}
    //           </div>

    //           <button
    //             className={`w-full py-3.5 rounded-lg text-xs font-medium transition-all ${
    //               card.isPopular
    //                 ? 'bg-[#4CC7B1] text-white hover:bg-[#3bb09b]'
    //                 : 'border border-black text-black hover:bg-gray-900 hover:text-white'
    //             }`}
    //           >
    //             Get Started
    //           </button>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // </div>
  );
};

export default SubscriptionSection;
