import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import axios from "axios";
import CryptoJS from "crypto-js";
import RejectedTradesInfoBanner from "./RejectedTradesInfoBanner"; // add this import
import {
  TrendingUp,
  EyeIcon,
  EyeOffIcon,
  Info,
  X,
  CandlestickChartIcon,
} from "lucide-react";

import { auth } from "../../firebase";
import server from "../../utils/serverConfig";
import PlaceOrders from "./PlaceOrders";
import NewStockCard from "../StockRecommendation/NewStockCard";
import LoadingSpinner from "../../components/LoadingSpinner";
import ConnectBroker from "../LivePortfolioSection/connectBroker";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import { fetchFunds } from "../../FunctionCall/fetchFunds";

import ReviewTradeModel from "../StockRecommendation/ReviewTradeModel";
import ZerodhaReviewModal from "../StockRecommendation/ZerodhaReviewModal";
import UpdateUserDeatils from "../LivePortfolioSection/UpdateUserDetails";
import RecommendationSuccessModal from "../StockRecommendation/RecommendationSuccessModal";
import { TokenExpireModal } from "../RootSection/TokenExpireModal";
import { IgnoreTradeModal } from "../StockRecommendation/IgnoreTradeModal";
import IsMarketHours from "../../utils/isMarketHours";
import { motion, AnimatePresence } from "framer-motion";
import DdpiModal from "../StockRecommendation/DdpiModal";
import { AngleOneTpinModal } from '../StockRecommendation/DdpiModal'
import { DhanTpinModal } from '../StockRecommendation/DdpiModal'
import { OtherBrokerModel } from "../StockRecommendation/DdpiModal";


const style = {
  selected:
    "flex items-center text-[12px] leading-[14px] lg:text-[16px]  font-poppins text-black font-bold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected:
    "flex items-center text-[12px] leading-[14px] font-medium font-poppins lg:text-[16px]  text-[#000000]/40 lg:leading-[42px] cursor-pointer",
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-[18px]  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full  ",
};

const defaultRationale = `This recommendation is based on a comprehensive analysis of the
        company's growth potential and value metrics. This recommendation
        also accounts for potential future risks, ensuring a balanced
        approach to maximizing returns while mitigating uncertainties.
        Please contact your advisor for any queries.`;

const OrderBookDetails = ({ getAllTradesUpdate,
setStockTypeAndSymbol,
  tradeClickCount,
  onOpenDhanTpinModal
 }) => {
  // user details fetch
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [userDetails, setUserDetails] = useState();
  const appURL = process.env.REACT_APP_URL;
  const zerodhaApiKey = process.env.REACT_APP_ZERODHA_API_KEY;
  const angelOneApiKey = process.env.REACT_APP_ANGEL_ONE_API_KEY;
  const brokerConnectRedirectURL =
    process.env.REACT_APP_BROKER_CONNECT_REDIRECT_URL;
  const getUserDeatils = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`)
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserDeatils();
  }, [userEmail, server.server.baseUrl]);

  const [brokerModel, setBrokerModel] = useState(null);
  const [brokerStatus, setBrokerStatus] = useState(
    userDetails ? userDetails.connect_broker_status : null
  );

  const [updateUserDetails, setUpdateUserDetails] = useState(false);
  useEffect(() => {
    if (userDetails && userDetails.user_broker !== undefined) {
      setBrokerStatus(userDetails && userDetails.connect_broker_status);
    }
  }, [userDetails, brokerStatus]);

  // all recommendation
  const [allTrades, setAllTrades] = useState([]);
  const [rejectedTrades, setRejectedTrades] = useState([]);
  const [setExecutedTrades] = useState([]);
  const getAllTrades = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/user/trade-reco-for-user?user_email=${userEmail}`,
    };
    axios
      .request(config)
      .then((response) => {
        setAllTrades(response.data.trades);

        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

        const rejectedTradesWithoutRebalance = response.data.trades.filter(
          (trade) => {
            const tradeDate = new Date(trade.date);
            return (
              trade.trade_place_status === "rejected" &&
              (trade.rebalance_status === undefined ||
                trade.rebalance_status === null) &&
              tradeDate >= sevenDaysAgo
            );
          }
        );
        // console.log(
        //   "Rejected trades without rebalance (last 7 days):",
        //   rejectedTradesWithoutRebalance
        // );

        setRejectedTrades(rejectedTradesWithoutRebalance);

        // Update executedTrades
        const executedTradesFiltered = response.data.trades.filter((trade) => {
          return (
            trade.trade_place_status !== "recommend" &&
            trade.trade_place_status !== "ignored"
          );
        });
        setExecutedTrades(executedTradesFiltered);
      })
      .catch((error) => {
        console.log("Error fetching trades:", error);
      });
  };
  useEffect(() => {
    getAllTrades();
  }, []);

  // executed tardes trades
  const filterExecutedTrades = () => {
    const filteredTrades = allTrades.filter((trade) => {
      return (
        trade.trade_place_status !== "recommend" &&
        trade.trade_place_status !== "ignored"
        // trade.trade_place_status !== "rejected"
      );
    });
    return filteredTrades;
  };
  const executedTrades = filterExecutedTrades();

  const [selectedRecommendation, setSelectedRecommendation] =
    useState("orders-placed");
  const [stockDetails, setStockDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const clientCode = userDetails && userDetails.clientCode;
  const apiKey = userDetails && userDetails.apiKey;
  const jwtToken = userDetails && userDetails.jwtToken;
  const my2pin = userDetails && userDetails.my2Pin;
  const secretKey = userDetails && userDetails.secretKey;
  const viewToken = userDetails && userDetails?.viewToken;
  const sid = userDetails && userDetails?.sid;
  const serverId = userDetails && userDetails?.serverId;
  const mobileNumber = userDetails && userDetails?.phone_number;
  const panNumber = userDetails && userDetails?.panNumber;

  const [broker, setBroker] = useState("");
  const [openReviewTrade, setOpenReviewTrade] = useState(false);
  const [openSuccessModal, setOpenSucessModal] = useState(false);
  const [orderPlacementResponse, setOrderPlacementResponse] = useState();
  const [openTokenExpireModel, setOpenTokenExpireModel] = useState(null);
  const [types, setTypes] = useState([]);
  const [showAngleOneTpinModel, setShowAngleOneTpinModel] = useState(false);
  const [showDhanTpinModel, setShowDhanTpinModel] = useState(false);
  const [showDdpiModal, setShowDdpiModal] = useState(false);

  const [edisStatus, setEdisStatus] = useState(null);
  const [dhanEdisStatus, setDhanEdisStatus] = useState(null);
  const [tradeType, setTradeType] = useState({
    allSell: false,
    allBuy:false,
    isMixed: false,
  });

  const [storedTradeType, setStoredTradeType] = useState(() => {

    const savedTradeType = localStorage.getItem('storedTradeType');
    return savedTradeType ? JSON.parse(savedTradeType) : { allSell: false, allBuy: false, isMixed: false };
  });



  const [showDhanTpinModal, setShowDhanTpinModal] = useState(false);
  const [singleStockTypeAndSymbol, setSingleStockTypeAndSymbol] = useState(null);
  
  const handleCloseDdpiModal = () => {
    setShowDdpiModal(false);
  };

  const handleProceedWithTpin = () => {
    setShowDdpiModal(false);

    setOpenZerodhaModel(true);
  };
  
  const handleOpenDhanTpinModal = (stockTypeAndSymbol) => {
    setSingleStockTypeAndSymbol(stockTypeAndSymbol);
    setShowDhanTpinModal(true);
  };


  const [isReturningFromOtherBrokerModal, setIsReturningFromOtherBrokerModal] =useState(false);

  useEffect(() => {
    if (userDetails) {
      setBroker(userDetails.user_broker);
    }
  }, [userDetails]);

  const dateString = userDetails && userDetails.token_expire;
  // Format the moment object as desired
  const expireTokenDate = moment(dateString).format("YYYY-MM-DD HH:mm:ss");

  const today = new Date();
  const todayDate = moment(today).format("YYYY-MM-DD HH:mm:ss");
  const userId = userDetails && userDetails._id;

  const checkValidApiAnSecret = (data) => {
    const bytesKey = CryptoJS.AES.decrypt(data, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8);
    if (Key) {
      return Key;
    }
  };

  const updatePortfolioData = () => {
    if (broker === "IIFL Securities") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}iifl/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "Kotak") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}kotak/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "Upstox") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}upstox/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "ICICI Direct") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}icici/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "Angel One") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}angelone/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "Zerodha") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}zerodha/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const placeOrder = () => {
    setLoading(true);
    // Prepare the payload based on broker
    const getOrderPayload = () => {
      const basePayload = {
        trades: stockDetails,
        user_broker: broker, // Add user_broker to identify the broker
      };

      switch (broker) {
        case "IIFL Securities":
          return {
            ...basePayload,
            clientCode,
          };
        case "ICICI Direct":
          return {
            ...basePayload,
            apiKey,
            secretKey,
            jwtToken,
          };
        case "Upstox":
          return {
            ...basePayload,
            apiKey,
            jwtToken,
            secretKey,
          };
        case "Kotak":
          return {
            ...basePayload,
            apiKey,
            secretKey,
            jwtToken,
            viewToken,
            sid,
            serverId,
          };
        case "Hdfc Securities":
          return {
            ...basePayload,
            apiKey,
            jwtToken,
          };
        case "Dhan":
          return {
            ...basePayload,
            clientCode,
            jwtToken,
          };
        case "AliceBlue":
          return {
            ...basePayload,
            clientCode,
            jwtToken,
            apiKey,
          };
        case "Fyers":
          return {
            ...basePayload,
            clientCode,
            jwtToken,
          };
          case "Angle One":
            return {
              ...basePayload,
              apiKey,
              secretKey,
              jwtToken,
            };
        default:
          return {
            ...basePayload,
            apiKey,
            jwtToken,
          };
      }
    };

    // const allBuy = stockDetails.every(
    //   (stock) => stock.transactionType === "BUY"
    // );
    // const allSell = stockDetails.every(
    //   (stock) => stock.transactionType === "SELL"
    // );
    // const isMixed = !allBuy && !allSell;


    const hasBuy = stockDetails.every( (stock) => stock.transactionType === "BUY");
    const hasSell = stockDetails.every((stock) => stock.transactionType === "SELL");
    const allSell = hasSell && !hasBuy;
    const allBuy = hasBuy && !hasSell;
    const isMixed = hasSell && hasBuy;
  
    setTradeType({
      allSell: allSell,
      allBuy:  allBuy,
      isMixed: isMixed,
    }); 


    const specialBrokers = [
      // "Dhan",
      "IIFL Securities",
      "ICICI Direct",
      "Upstox",
      "Kotak",
      "Hdfc Securities",
      "AliceBlue",
      
    ];


    function checkAndResetRejectedCount() {
      const resetTime = localStorage.getItem("rejectedOrdersResetTime");
      const currentTime = new Date().getTime();
    
      // If there's no resetTime or it's past the reset time, reset the count
      // if (!resetTime || currentTime >= parseInt(resetTime)) {
      //   console.log("Resetting rejectedOrdersCount to 0");
      //   localStorage.setItem("rejectedOrdersCount", "0");
    

      if (!resetTime || currentTime >= parseInt(resetTime)) {
        console.log("Resetting all broker rejected counts");
        ["Dhan", "IIFL Securities", "ICICI Direct", "Upstox", "Kotak", "Hdfc Securities", "AliceBlue", "Fyers", "Angel One"]
          .forEach((broker) => {
            localStorage.setItem(`rejectedCount${broker.replace(/ /g, "")}`, "0");
          });
  


        // Set the next reset time to 12:00 AM of the next day
        const nextResetTime = new Date();
        nextResetTime.setDate(nextResetTime.getDate() + 1); // Move to the next day
        nextResetTime.setHours(0, 0, 0, 0); // Set to midnight (12:00 AM)
        localStorage.setItem("rejectedOrdersResetTime", nextResetTime.getTime().toString());
        console.log("Next reset time set to:", nextResetTime.toLocaleString());
      }
    }
    
    // Call the function at the start
    checkAndResetRejectedCount();
    



// Retrieve the rejected count from localStorage
// const rejectedSellCount = parseInt(localStorage.getItem("rejectedOrdersCount") || "0");

const rejectedKey = `rejectedCount${broker.replace(/ /g, "")}`;
const rejectedSellCount = parseInt(localStorage.getItem(rejectedKey) || "0");



if (!isReturningFromOtherBrokerModal && specialBrokers.includes(broker) ) {
  if (allBuy) {
        console.log("All trades are BUY for broker:", broker);
        // Proceed with order placement for BUY
      } else if ((allSell || isMixed) && rejectedSellCount === 1) {
        console.log(
          allSell ? "All trades are SELL" : "Trades are Mixed",
          "for broker:",
          broker
        );

        
        setShowOtherBrokerModel(true);

        setOpenReviewTrade(false);

        setLoading(false);
        return; // Exit the function early
      }
    }

  
    
    try {


    const orderConfig = {
      method: "post",
      url: `${server.server.baseUrl}api/process-trades/order-place`, // Single unified endpoint
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(getOrderPayload()),
    };

    setLoading(false);
    console.log("Order Config Data:", orderConfig.data);

    // const rejectedSellCount = orderConfig.data.response.reduce((count, order) => {
    //   return order.orderStatus === "rejected" && order.transactionType === "SELL" ? count + 1 : count
    //   }, 0)
    const response = orderConfig.data; // Assuming this is the response from your API
    const trades = response?.trades || []; // Extract trades array safely
    
    // Calculate the rejectedSellCount
    const rejectedSellCount = trades.reduce((count, trade) => {
      return trade.trade_place_status === "rejected" && trade.transactionType === "SELL" 
        ? count + 1 
        : count;
    }, 0);


      const currentRejectedCount = parseInt(localStorage.getItem(rejectedKey) || "0");
      const newRejectedCount = currentRejectedCount + rejectedSellCount;
      localStorage.setItem(rejectedKey, newRejectedCount.toString());
  
      console.log(`${broker} Rejected Sell Count:`, newRejectedCount);


      if (newRejectedCount !== 1) {
        console.log("Setting openSuccessModal to true");
        setOpenSucessModal(true);
      } else {
        console.log("Setting AfterPlaceOrderDdpiModal to true");

        if (!isReturningFromOtherBrokerModal && specialBrokers.includes(broker) ) {
          if (allBuy) {
                console.log("All trades are BUY for broker:", broker);
                // Proceed with order placement for BUY
              } else if ((allSell || isMixed) && rejectedSellCount === 1) {
                console.log(
                  allSell ? "All trades are SELL" : "Trades are Mixed",
                  "for broker:",
                  broker
                );
        
                
                setShowOtherBrokerModel(true);
        
                setOpenReviewTrade(false);
        
                setLoading(false);
                return; // Exit the function early
              }
            }

            // if (newRejectedCount === 1 &&  (broker === "Dhan" || broker === "Fyers" || broker === "Angel One")
            // ) {
            //   console.log("Setting AfterPlaceOrderDdpiModal to true for", broker);
            //   setShowAfterPlaceOrderDdpiModal(true);
            // } else {
            //   console.log("Setting openSuccessModal to true");
            //   setOpenSucessModal(true);
            // }            
            setOpenSucessModal(true);
          }


                 setOrderPlacementResponse(orderConfig.data.response);
                    setOpenReviewTrade(false);

// const rejectedSellCount = response.data.response.reduce((count, order) => {
//   return order.orderStatus === "rejected" && order.transactionType === "SELL" ? count + 1 : count;
// }, 0);

console.log(`Total rejected orders: ${newRejectedCount}`);

                  



    // Make the API call
    axios
      .request(orderConfig)
      .then((response) => {
        setLoading(false);
        setOpenSucessModal(true);
        setOrderPlacementResponse(response.data.response);
        setOpenReviewTrade(false);

        // Update all necessary data
        getAllTrades();
        updatePortfolioData();
        if (getAllTradesUpdate) {
          getAllTradesUpdate();
        }
        getCartAllStocks();
      })
      .catch((error) => {
        console.error("Error placing order:", error);
        setLoading(false);
        toast.error(
          "There was an issue in placing the trade, please try again after sometime or contact your advisor",
          {
            duration: 6000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "500px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          }
        );
      });
    }
    finally {
      setIsReturningFromOtherBrokerModal(false);
    }
  };

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  // user login

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const handleIiflLogin = () => {
    setLoginLoading(true);
    let data = JSON.stringify({
      clientCode: clientCode,
      password: password,
      my2pin: my2pin,
      userId: userId,
    });

    let config = {
      method: "post",
      url: `${server.server.baseUrl}api/iifl/generate-session`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setLoginLoading(false);
        getUserDeatils();
        setOpenTokenExpireModel(false);
        toast.success(
          "You have been successfully logged in to IIFL Securities",
          {
            duration: 5000,
            style: {
              background: "white",
              color: "#16a085",
              maxWidth: "550px",
              fontWeight: "bolder",
              fontSize: "16px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      })
      .catch((error) => {
        const result = error.response.data.response;
        setLoginLoading(false);
        toast.error(`${result.message}`, {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  useEffect(() => {
    if (showSuccessMsg) {
      setShowSuccessMsg(true);
      const toRef = setTimeout(() => {
        setShowSuccessMsg(false);
        clearTimeout(toRef);
      }, 4000);
    }
  }, [showSuccessMsg]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // ignore trade
  const [openIgnoreTradeModel, setOpenIgnoreTradeModel] = useState(false);
  const [stockIgnoreId, setStockIgnoreId] = useState();
  const [ignoreLoading, setIgnoreLoading] = useState(false);
  const [ignoreText, setIgnoreText] = useState("");

  const handleIgnoredTrades = (id) => {
    setIgnoreLoading(true);
    let data = JSON.stringify({
      uid: id,
      trade_place_status: "ignored",
      reason: ignoreText,
    });

    // Second API request to place the order
    let orderConfig = {
      method: "put",
      url: `${server.server.baseUrl}api/recommendation`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(orderConfig)
      .then((response) => {
        toast.success("You have successfully ignored your trade", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        setIgnoreLoading(false);
        setOpenIgnoreTradeModel(false);
        getAllTrades();
      })
      .catch((error) => {
        console.error(`Error placing order for stock `, error);
        setLoading(false);
      });
  };

  const { getLTPForSymbol } = useWebSocketCurrentPrice(rejectedTrades);

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    stockDetails.forEach((ele) => {
      if (ele.transactionType === "BUY") {
        const ltp = getLTPForSymbol(ele.tradingSymbol); // Get LTP for current symbol
        if (ltp !== "-") {
          totalAmount += parseFloat(ltp) * ele.quantity; // Calculate total amount for this trade
        }
      }
    });
    return totalAmount.toFixed(2); // Return total amount formatted to 2 decimal places
  };

  const [openZerodhaModel, setOpenZerodhaModel] = useState(false);
  const [selectedLength, setSelectedLength] = useState();
  const [singleStockSelectState, setSingleStockSelectState] = useState(false);
  const [showOtherBrokerModel, setShowOtherBrokerModel] = useState(false);

  useEffect(() => {
    const storedTradeType = localStorage.getItem('storedTradeType');
    if (storedTradeType) {
      setTradeType(JSON.parse(storedTradeType));
    }
  }, []);

  useEffect(() => {
    // Update localStorage whenever tradeType changes
    localStorage.setItem('storedTradeType', JSON.stringify(tradeType));
  }, [tradeType])

  const getCartAllStocks = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/cart/${userEmail}?trade_place_status=rejected`,
    };

    axios
      .request(config)
      .then((response) => {
        const transformedStockDetails = response?.data
          .filter(
            (stock) =>
              stock.trade_place_status === "rejected" &&
              (stock.rebalance_status === undefined ||
                stock.rebalance_status === null)
          )
          .map((stock) => ({
            user_email: stock.user_email,
            trade_given_by: stock.trade_given_by,
            tradingSymbol: stock.Symbol,
            transactionType: stock.Type,
            exchange: stock.Exchange,
            segment: stock.Segment,
            productType: stock.ProductType,
            orderType: stock.OrderType,
            price: stock.Price,
            quantity: stock.Quantity,
            priority: stock.Priority,
            tradeId: stock.tradeId,
            user_broker: broker,
            trade_place_status: stock.trade_place_status,
            rebalance_status: stock.rebalance_status,
          }));
     // Extract and store all Types to use this in handletrade

     const extractedTypes = response?.data?.map((stock) => stock.Type);
     setTypes(extractedTypes);

    //  console.log("setTypes",extractedTypes)
        setStockDetails(transformedStockDetails);
        setSelectedLength(transformedStockDetails);
        const hasSell = extractedTypes.some((type) => type === "SELL");
        const hasBuy = extractedTypes.some((type) => type === "BUY");
                const allSell = hasSell && !hasBuy;
        const allBuy = hasBuy && !hasSell;
        const isMixed = hasSell && hasBuy;
        
                setTradeType({
                  allSell: allSell,
                  allBuy:  allBuy,
                  isMixed: isMixed,
                });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (userEmail) {
      getCartAllStocks();
    }
  }, [userEmail]);

  const handleSelectAllRejectedStocks = async () => {
    const newStockDetails = rejectedTrades.reduce((acc, stock) => {
      const isSelected = stockDetails.some(
        (selectedStock) =>
          selectedStock.tradingSymbol === stock.Symbol &&
          selectedStock.tradeId === stock.tradeId
      );

      if (!isSelected) {
        const ltp = getLTPForSymbol(stock.Symbol);
        const advisedRangeLower = stock.Advised_Range_Lower;
        const advisedRangeHigher = stock.Advised_Range_Higher;

        const shouldDisableTrade =
          (advisedRangeHigher === 0 && advisedRangeLower === 0) ||
          (advisedRangeHigher === null && advisedRangeLower === null) ||
          (advisedRangeHigher > 0 &&
            advisedRangeLower > 0 &&
            parseFloat(advisedRangeHigher) > parseFloat(ltp) &&
            parseFloat(ltp) > parseFloat(advisedRangeLower)) ||
          (advisedRangeHigher > 0 &&
            advisedRangeLower === 0 &&
            advisedRangeLower === null &&
            parseFloat(advisedRangeHigher) > parseFloat(ltp)) ||
          (advisedRangeLower > 0 &&
            advisedRangeHigher === 0 &&
            advisedRangeHigher === null &&
            parseFloat(advisedRangeLower) < parseFloat(ltp));

        if (shouldDisableTrade) {
          const newStock = {
            user_email: stock.user_email,
            trade_given_by: stock.trade_given_by,
            tradingSymbol: stock.Symbol,
            transactionType: stock.Type,
            exchange: stock.Exchange,
            segment: stock.Segment,
            productType: stock.ProductType,
            orderType: stock.OrderType,
            price: stock.Price,
            quantity: stock.Quantity,
            priority: stock.Priority,
            tradeId: stock.tradeId,
            user_broker: broker,
          };
          acc.push(newStock);
        }
      }

      return acc;
    }, []);

    if (newStockDetails.length > 0) {
      try {
        await axios.post(
          `${server.server.baseUrl}api/cart/add/add-multiple-to-cart`,
          {
            stocks: newStockDetails,
          }
        );
        // Optionally, update the state to reflect the changes in the UI
        getCartAllStocks();
      } catch (error) {
        console.error("Error adding stocks to cart", error);
      }
    }
  };

  const handleRemoveAllSelectedStocks = async () => {
    try {
      // Use all stock details in the cart for removal
      const stocksToRemove = [...stockDetails];

      if (stocksToRemove.length > 0) {
        await axios.post(
          `${server.server.baseUrl}api/cart/cart-items/remove/multiple/remove-multiple-from-cart`,
          {
            stocks: stocksToRemove,
          }
        );

        // Clear stockDetails since all stocks are removed
        setStockDetails([]);
        getCartAllStocks(); // Refresh the cart
      }
    } catch (error) {
      console.error("Error removing stocks from cart", error);
    }
  };

  // zerodha start
  const handleZerodhaRedirect = async () => {
    localStorage.setItem(
      "stockDetailsZerodhaOrder",
      JSON.stringify(stockDetails)
    );
    const apiKey = zerodhaApiKey;

    const basket = stockDetails.map((stock) => {
      let baseOrder = {
        variety: "regular",
        tradingsymbol: stock.tradingSymbol,
        exchange: stock.exchange,
        transaction_type: stock.transactionType,
        order_type: stock.orderType,
        quantity: stock.quantity,
        readonly: false,
        price: stock.price,
      };

      // Get the LTP for the current stock
      const ltp = getLTPForSymbol(stock.tradingSymbol);

      // If LTP is available and not '-', use it as the price
      if (ltp !== "-") {
        baseOrder.price = parseFloat(ltp);
      }

      if (stock.orderType === "LIMIT") {
        // For LIMIT orders, always use the limit price specified
        baseOrder.price = parseFloat(stock.price || 0);
      } else if (stock.orderType === "MARKET") {
        // For MARKET orders, get LTP
        const ltp = getLTPForSymbol(stock.tradingSymbol);
        if (ltp !== "-") {
          baseOrder.price = parseFloat(ltp);
        } else {
          baseOrder.price = 0; // Default price for market orders
        }
      }

      if (stock.quantity > 100) {
        baseOrder.readonly = true;
      }

      return baseOrder;
    });

    const form = document.createElement("form");
    form.method = "POST";

    form.action = `https://kite.zerodha.com/connect/basket`;

    // form.target = "_blank";

    const apiKeyInput = document.createElement("input");
    apiKeyInput.type = "hidden";
    apiKeyInput.name = "api_key";
    apiKeyInput.value = apiKey;

    const dataInput = document.createElement("input");
    dataInput.type = "hidden";
    dataInput.name = "data";
    dataInput.value = JSON.stringify(basket);

    const redirectParams = document.createElement("input");
    redirectParams.type = "hidden";
    redirectParams.name = "redirect_params";
    redirectParams.value = `${appURL}=true`;

    form.appendChild(apiKeyInput);
    form.appendChild(dataInput);
    form.appendChild(redirectParams);

    document.body.appendChild(form);

    const currentISTDateTime = new Date();
    try {
      // Update the database with the current IST date-time
      await axios.put(`${server.server.baseUrl}api/zerodha/update-trade-reco`, {
        stockDetails: stockDetails,
        leaving_datetime: currentISTDateTime,
      });

      // Submit the form after the database is updated
      form.submit();
    } catch (error) {
      console.error("Failed to update trade recommendation:", error);
    }
  };

  const todaysDate = new Date().toLocaleDateString();

  const filterTodaysRejectedTrades = rejectedTrades
    .map((trade) => ({
      ...trade,
      date: new Date(trade.date).toLocaleDateString(),
    }))
    .filter(
      (trade) =>
        trade.date === todaysDate &&
        trade.trade_place_status === "rejected" &&
        (trade.rebalance_status === undefined ||
          trade.rebalance_status === null)
    );

  const filterTodaysExecutedTrades = executedTrades
    .map((trade) => ({
      ...trade,
      date: new Date(trade.purchaseDate || trade.exitDate).toLocaleDateString(),
    }))
    .filter((trade) => trade.date === todaysDate);

  const [funds, setFunds] = useState({});
  const getAllFunds = async () => {
    const fetchedFunds = await fetchFunds(
      broker,
      clientCode,
      apiKey,
      jwtToken,
      secretKey
    );
    if (fetchedFunds) {
      setFunds(fetchedFunds);
    } else {
      console.error("Failed to fetch funds.");
    }
  };

  useEffect(() => {
    // Call the function when the component mounts or when relevant props change
    if (broker && (clientCode || jwtToken)) {
      getAllFunds();
    }
  }, [broker, clientCode, apiKey, jwtToken, secretKey]);



  useEffect(() => {
    if (userDetails && userDetails.user_broker === "Angel One") {
      const verifyEdis = async () => {
        try {
          const response = await axios.post(
            "https://ccxtprod.alphaquark.in/angelone/verify-edis",
            {
              apiKey: angelOneApiKey,
              jwtToken: userDetails.jwtToken,
              userEmail: userDetails?.email,
            }
          );
          setEdisStatus(response.data);
          console.log("AngleOne response", response.data);
        } catch (error) {
          console.error("Error verifying eDIS status:", error);
        }
      };

      verifyEdis();
    }
  }, [userDetails]);

// console.log("EdisStatus",edisStatus?.edis)

  //fetching edis status for Dhan

  useEffect(() => {
    if (userDetails && userDetails.user_broker === "Dhan") {
      const verifyDhanEdis = async () => {
        try {
          const response = await axios.post(
            "https://ccxtprod.alphaquark.in/dhan/edis-status",
            {
              clientId: clientCode,
              accessToken: userDetails.jwtToken,
            }
          );
          console.log("Dhan Reponse", response.data);

          setDhanEdisStatus(response.data);
        } catch (error) {
          console.error("Error verifying eDIS status:", error);
        }
      };

      verifyDhanEdis();
    }
  }, [userDetails]);

 

  const handleTrade = () => {
    
    getAllFunds();
    const isFundsEmpty = funds?.status === false;
    // const isMarketHours = IsMarketHours();

    // if (!isMarketHours) {
    //   toast.error("Orders cannot be placed outside Market hours.", {
    //     duration: 3000,
    //     style: {
    //       background: "white",
    //       color: "#1e293b",
    //       maxWidth: "500px",
    //       fontWeight: 600,
    //       fontSize: "13px",
    //       padding: "10px 20px",
    //     },
    //     iconTheme: {
    //       primary: "#e43d3d",
    //       secondary: "#FFFAEE",
    //     },
    //   });
    //   return;
    // }
  //   if (broker === "Zerodha") {
  //     if (todayDate > expireTokenDate || isFundsEmpty) {
  //       setOpenTokenExpireModel(true);
  //     } else {
  //       setOpenZerodhaModel(true);
  //     }
  //   } else {
  //     if (brokerStatus === null) {
  //       setBrokerModel(true);
  //     } else if (todayDate > expireTokenDate || isFundsEmpty) {
  //       setOpenTokenExpireModel(true);
  //     } else {
  //       setOpenReviewTrade(true);
  //     }
  //   }
  // };
  // const hasBuy = types.every((type) => type === "BUY");
  // const hasSell = types.every((type) => type === "SELL");
  // const allSell = hasSell && !hasBuy;
  // const allBuy = hasBuy && !hasSell;
  // const isMixed = hasSell && hasBuy;

  // const newTradeType = {
  //   allSell: allSell,
  //   allBuy: allBuy,
  //   isMixed: isMixed,
  // };

  const storedTradeType = JSON.parse(localStorage.getItem('storedTradeType') || '{}');
  const { allBuy, allSell, isMixed } = storedTradeType;
  // localStorage.setItem('storedTradeType', JSON.stringify(newTradeType));

  console.log("storedTradeType", storedTradeType);


//updated code for  ddpi logic
  // If broker is Zerodha, handle fund checks
    if (broker === "Zerodha") {
      if (todayDate > expireTokenDate || isFundsEmpty) {
        setOpenTokenExpireModel(true);
      } else {
        setOpenZerodhaModel(true);
      }
    } else {
      if (brokerStatus === null) {
        setBrokerModel(true);
      } else if (todayDate > expireTokenDate || isFundsEmpty) {
        setOpenTokenExpireModel(true);
      }
    else {
      //   setOpenReviewTrade(true)
      // }

      // Check edisStatus for Angel One just before setting openReviewTrade
      // if (broker === "Angel One") {
      //   if (edisStatus && edisStatus.edis === true) {
      //     setOpenReviewTrade(true)
      //   } else {
      //     // console.log("edis dtatus",edisStatus )
      //     setShowAngleOneTpinModel(true)
      //   }
      // }
      if (broker === "Angel One" ) {
        if (edisStatus && edisStatus.edis === true ) {
          if (allBuy) {
            // Logic for all BUY trades with Angel One
            console.log("All trades are BUY for Angel One.");
            setOpenReviewTrade(true); // Open review trade modal for BUY
          } else if (allSell) {
            // Logic for all SELL trades with Angel One
            console.log("All trades are SELL for Angel One.");
            setOpenReviewTrade(true); // Open review trade modal for SELL
          } else if (isMixed) {
            // Logic for Mixed trades with Angel One
            console.log("Trades are Mixed for Angel One.");
            setOpenReviewTrade(true); // Open review trade modal for Mixed
          }
        } else if (edisStatus && edisStatus.edis === false  && allSell || isMixed  ) {
          console.log("edisStatus is missing or not valid for Angel One.");
          setShowAngleOneTpinModel(true); // Show TPIN modal if edisStatus is not valid
        }

        else {
          setOpenReviewTrade(true);
        }
      }
      // else if (broker === "Dhan") {
      //   // if (edisStatus?.edis === true) {
      //   //   setOpenReviewTrade(true);
      //   // } else {
      //    setShowDhanTpinModel(true);
      //   // }
      // // }
      // else if (broker === "Dhan") {
      //   if (allBuy) {
      //     // Logic for all BUY trades with Dhan
      //     console.log("All trades are BUY for Dhan.");
      //     setOpenReviewTrade(true); // Open the review trade modal for BUY
      //   } else if (allSell && dhanEdisStatus && dhanEdisStatus?.data?.[0]?.edis === false) {
      //     // Logic for all SELL trades with Dhan
      //     console.log("All trades are SELL for Dhan.");
      //     setShowDhanTpinModel(true); // Open the TPIN modal for SELL
      //   } else if (isMixed && dhanEdisStatus && dhanEdisStatus?.data?.[0]?.edis === false) {
      //     // Logic for Mixed trades with Dhan
      //     console.log("Trades are Mixed for Dhan.");
      //     setShowDhanTpinModel(true); // Treat Mixed similar to SELL or modify as per requirement
      //   }
      else if (broker === "Dhan") {
        if (allBuy) {
          // Logic for all BUY trades with Dhan
          console.log("All trades are BUY for Dhan.");
          setOpenReviewTrade(true); // Open the review trade modal for BUY
        } else if (allSell) {
          if (dhanEdisStatus?.data?.[0]?.edis === true) {
            // Logic for SELL trades when `edis` is true
            console.log("All trades are SELL for Dhan, and edis is true.");
            setOpenReviewTrade(true); // Open the review trade modal
          } else if (dhanEdisStatus?.data?.[0]?.edis === false) {
            // Logic for SELL trades when `edis` is false
            console.log("All trades are SELL for Dhan, and edis is false.");
            setShowDhanTpinModel(true); // Open the TPIN modal
          }
        } else if (isMixed) {
          if (dhanEdisStatus?.data?.[0]?.edis === true) {
            // Logic for Mixed trades when `edis` is true
            console.log("Trades are Mixed for Dhan, and edis is true.");
            setOpenReviewTrade(true); // Open the review trade modal
          } else if (dhanEdisStatus?.data?.[0]?.edis === false && !allBuy) {
            // Logic for Mixed trades when `edis` is false
            console.log("Trades are Mixed for Dhan, and edis is false.");
            setShowDhanTpinModel(true); // Open the TPIN modal
          }
        } else {
          // Fallback: Open the review trade modal
          console.log("Fallback condition met: Opening review trade modal.");
          setOpenReviewTrade(true);
        }
      

      } else if (broker === "Fyers") {
        if (edisStatus?.edis === true) {
          setOpenReviewTrade(true);
        }
        //  else {
        //   setShowTpinModel(true);
        // }
      }

      // else if (
      //    userDetails.user_broker ===  "IIFL" ||
      //   // userDetails.user_broker === "Angel One"||
      //    userDetails.user_broker ===  "ICICI Direct" ||
      //    userDetails.user_broker ===  "Upstox" ||
      //    userDetails.user_broker ===  "Kotak" ||
      //    userDetails.user_broker ===  "HDFC" ||
      //    userDetails.user_broker ===  "AliceBlue"
      //   )
      //    {
      //    if (allBuy) {
      //           //  Buy Logic
      //       console.log("All trades are BUY for other broker:", userDetails.user_broker);
      //       setShowOtherBrokerModel(false); // Assuming BUY doesn't need the other broker modal
      //       setOpenReviewTrade(true); // Show trade review modal
      //           } else if (allSell) {
      //          // Apply Sell Logic
      //        console.log("All trades are SELL for other broker:", userDetails.user_broker);
      //         setShowOtherBrokerModel(true); // Show specific broker model for Sell
      //      } else if (isMixed) {
      //            // Apply Mixed Logic
      //          console.log("Trades are Mixed for other broker:", userDetails.user_broker);
      //                setShowOtherBrokerModel(true);// Handle similarly to SELL or as needed
      //                  }
      // }
      else {
        setOpenReviewTrade(true);
      }
    }
  }

  if (allBuy) {
    // If all trades are BUY, ignore DDPI modal
    console.log("All trades are BUY. Ignoring DDPI modal.");
  } else if (allSell || isMixed) {
    console.log("All sells got ");

    // If all trades are SELL or mixed, proceed with conditions
    if (userDetails.ddpi_status === null) {
 
      setShowDdpiModal(true);
      setOpenZerodhaModel(false);
    } else if (
      userDetails.ddpi_status === "consent" ||
      userDetails.ddpi_status === "physical"
    ) {
      // reverse condition for testing
      // (userDetails.ddpi_status === null)
      setShowDdpiModal(false);
    }
  }
  console.log(`Trade button clicked ${tradeClickCount + 1} times`);
};


  const handleOpenReviewModal = () => {
    setOpenReviewTrade(false);
    setSingleStockSelectState(false);
    getCartAllStocks();
  };

  const handleOpenZerodhaModal = () => {
    setOpenZerodhaModel(false);
    setSingleStockSelectState(false);
    getCartAllStocks();
  };

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col relative  w-full min-h-screen  bg-[#f9f9f9]">
          <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
            <svg
              className="h-10 w-10 text-[#000000] animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        <div className="flex flex-col relative  w-full max-h-[calc(100vh-60px)] md:min-h-screen  bg-[#f9f9f9]">
          <div className="flex flex-col  w-full min-h-screen  bg-[#f9f9f9] ">
            <Toaster position="top-center" reverseOrder={true} />
            <div className="px-4 lg:px-[50px] flex flex-row  justify-between border-b border-[#000000]/20  h-[50px] lg:h-[60px] font-poppins">
              <div className="flex flex-row space-x-6 lg:space-x-8 ">
                <div
                  className={
                    selectedRecommendation === "orders-placed"
                      ? ` ${style.selected}`
                      : ` ${style.unselected}`
                  }
                  onClick={() => {
                    setSelectedRecommendation("orders-placed");
                    getAllTrades();
                  }}
                >
                  Orders Placed
                  {filterTodaysExecutedTrades &&
                    filterTodaysExecutedTrades.length > 0 && (
                      <span
                        className={`ml-2 pt-[1px] px-[4px] flex items-center text-[11px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[28px] h-5 rounded-full ${
                          selectedRecommendation !== "orders-placed"
                            ? "bg-[#000000]/30"
                            : filterTodaysExecutedTrades.length === 0
                            ? "bg-[#000000]/30"
                            : "bg-[#E43D3D]"
                        }`}
                      >
                        {filterTodaysExecutedTrades
                          ? filterTodaysExecutedTrades.length
                          : ""}
                      </span>
                    )}
                </div>
                <div
                  className={
                    selectedRecommendation === "rejected-orders"
                      ? ` ${style.selected}`
                      : ` ${style.unselected}`
                  }
                  onClick={() => {
                    setSelectedRecommendation("rejected-orders");
                    getAllTrades();
                    getAllFunds();
                  }}
                >
                  Rejected Orders
                  {filterTodaysRejectedTrades &&
                    filterTodaysRejectedTrades.length > 0 && (
                      <span
                        className={` ml-2 pt-[1px] px-[4px] flex items-center text-[11px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[28px] h-5 rounded-full ${
                          selectedRecommendation !== "rejected-orders"
                            ? "bg-[#000000]/30"
                            : filterTodaysRejectedTrades.length === 0
                            ? "bg-[#000000]/30"
                            : "bg-[#E43D3D]"
                        }`}
                      >
                        {filterTodaysRejectedTrades
                          ? filterTodaysRejectedTrades.length
                          : ""}
                      </span>
                    )}
                </div>
              </div>

              {selectedRecommendation === "rejected-orders" ? (
                <div className="hidden md:flex flex-row items-center py-3">
                  {rejectedTrades.length !== 0 && (
                    <>
                      {rejectedTrades.length === stockDetails.length ? (
                        <div
                          onClick={handleRemoveAllSelectedStocks}
                          className=" text-xs md:text-lg py-2 hidden md:block md:px-6 text-[#000000] font-medium font-poppins border-[1px] border-[#000000]/20 rounded-md cursor-pointer"
                        >
                          <span className="">Deselect All</span>
                        </div>
                      ) : (
                        <div
                          onClick={handleSelectAllRejectedStocks}
                          className="text-xs md:text-lg py-2 hidden md:block md:px-6 text-[#000000] font-medium font-poppins border-[1px] border-[#000000]/20 rounded-md cursor-pointer"
                        >
                          <span className="">Select All</span>
                        </div>
                      )}
                    </>
                  )}

                  <button
                    className="w-[80px] lg:w-[150px]   disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-2 py-1.5 bg-black text-[14px] lg:ml-6 lg:text-lg lg:px-4 lg:py-2 text-white font-medium rounded-md cursor-pointer"
                    onClick={handleTrade}
                    disabled={stockDetails?.length === 0}
                  >
                    <div className="flex flex-row justify-center items-center space-x-2">
                      {" "}
                      <TrendingUp className=" w-4 h-4 lg:h-5 lg:w-5" />
                      <span>Trade</span>
                      {singleStockSelectState === true ? (
                        <span className="font-medium mt-0.5 md:mt-1">
                          (
                          {(stockDetails?.length || 0) +
                            (selectedLength?.length || 0)}
                          )
                        </span>
                      ) : (
                        stockDetails?.length > 0 && (
                          <span className="font-medium mt-0.5 md:mt-1">
                            ({stockDetails?.length})
                          </span>
                        )
                      )}
                    </div>
                  </button>
                </div>
              ) : null}
            </div>

            {selectedRecommendation === "orders-placed" && (
              <PlaceOrders
                executedTrades={executedTrades}
                userEmail={userEmail}
              />
            )}

            {selectedRecommendation === "rejected-orders" && (
              <>
                <RejectedTradesInfoBanner />
                <div className="h-[calc(150vh-220px)] overflow-auto w-full">
                  {/* <div className="text-center text-sm text-gray-600 mb-4">
        Showing rejected trades from the last 7 days
      </div> */}

                  {rejectedTrades?.length > 0 ? (
                    <AnimatePresence mode="wait">
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="w-full grid gap-y-6 py-4 px-2 pb-[40px] md:pb-0 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-4 lg:pt-6 lg:pb-12 lg:px-[50px]"
                      >
                        {rejectedTrades.map((ele, i) => (
                          <NewStockCard
                            key={i}
                            id={ele._id}
                            isSelected={stockDetails.some(
                              (stock) =>
                                stock.tradingSymbol === ele.Symbol &&
                                stock.tradeId === ele.tradeId
                            )}
                            symbol={ele.Symbol}
                            Price={ele.Price}
                            date={ele.date}
                            Quantity={ele.Quantity}
                            action={ele.Type}
                            orderType={ele.OrderType}
                            exchange={ele.Exchange}
                            segment={ele.Segment}
                            cmp={ele.CMP}
                            tradeId={ele.tradeId}
                            rationale={
                              ele?.rationale ? ele.rationale : defaultRationale
                            }
                            recommendationStock={rejectedTrades}
                            setRecommendationStock={setRejectedTrades}
                            setStockDetails={setStockDetails}
                            stockDetails={stockDetails}
                            setOpenReviewTrade={setOpenReviewTrade}
                            setOpenIgnoreTradeModel={setOpenIgnoreTradeModel}
                            setStockIgnoreId={setStockIgnoreId}
                            getLTPForSymbol={getLTPForSymbol}
                            setOpenTokenExpireModel={setOpenTokenExpireModel}
                            setOpenZerodhaModel={setOpenZerodhaModel}
                            todayDate={todayDate}
                            expireTokenDate={expireTokenDate}
                            brokerStatus={brokerStatus}
                            setBrokerModel={setBrokerModel}
                            funds={funds?.data?.availablecash}
                            advisedRangeLower={ele.Advised_Range_Lower}
                            advisedRangeHigher={ele.Advised_Range_Higher}
                            setSingleStockSelectState={
                              setSingleStockSelectState
                            }
                            getCartAllStocks={getCartAllStocks}
                            getAllFunds={getAllFunds}
                            broker={broker}
                            edisStatus={edisStatus}
                       
                            setShowAngleOneTpinModel={setShowAngleOneTpinModel}
                            dhanEdisStatus={dhanEdisStatus}
                            setShowDhanTpinModel={setShowDhanTpinModel}
                            setShowDdpiModal={setShowDdpiModal}
                            setTradeType={setTradeType}

                            onOpenDhanTpinModal={handleOpenDhanTpinModal}

                          />
                        ))}
                      </motion.div>
                    </AnimatePresence>
                  ) : (
                    <div className="flex flex-col space-y-4 lg:space-y-6 lg:pt-[20px] items-center justify-center h-[calc(100vh-240px)] lg:h-full">
                      <div className="flex items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
                        <CandlestickChartIcon className="w-[50px] h-[50px] lg:w-[60px] lg:h-[60px]" />
                      </div>
                      <div className="flex flex-col space-y-3 lg:space-y-3 items-center">
                        <div className="text-balck text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                          No Rejected Trades
                        </div>
                        <div className="text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[30px] font-normal font-poppins px-[10px] lg:px-[60px]">
                          No rejected trades from the last 7 days
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            {selectedRecommendation === "rejected-orders" &&
              rejectedTrades.length > 0 && (
                <div className="absolute  bottom-0 bg-[#f9f9f9] shadow-[rgba(0,_0,_0,_0.4)_0px_30px_90px] border-[1px] border-[#000000]/10 flex flex-row items-center justify-between  h-[65px] px-4 space-x-4 w-full md:hidden">
                  {rejectedTrades.length === stockDetails.length ? (
                    <div
                      onClick={handleRemoveAllSelectedStocks}
                      className=" w-full flex items-center justify-center  text-[15px] py-3 px-2   text-[#000000] font-medium font-poppins border-[1px] border-[#000000]/20 rounded-md cursor-pointer"
                    >
                      <span className="">Deselect All</span>
                    </div>
                  ) : (
                    <div
                      onClick={handleSelectAllRejectedStocks}
                      className=" w-full flex items-center justify-center text-[15px] py-3 px-2   text-[#000000] font-medium font-poppins border-[1px] border-[#000000]/20 rounded-md cursor-pointer"
                    >
                      <span className="">Select All</span>
                    </div>
                  )}
                  <button
                    className="w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-2 py-3 bg-black text-[15px]  text-white font-medium rounded-md cursor-pointer"
                    onClick={handleTrade}
                    disabled={stockDetails?.length === 0}
                  >
                    <div className="flex flex-row justify-center items-center space-x-2">
                      {" "}
                      <TrendingUp className=" w-4 h-4 lg:h-5 lg:w-5" />
                      <span>Trade</span>
                      {singleStockSelectState === true ? (
                        <span className="font-medium mt-0.5 md:mt-1">
                          (
                          {(stockDetails?.length || 0) +
                            (selectedLength?.length || 0)}
                          )
                        </span>
                      ) : (
                        stockDetails?.length > 0 && (
                          <span className="font-medium mt-0.5 md:mt-1">
                            ({stockDetails?.length})
                          </span>
                        )
                      )}
                    </div>
                  </button>
                </div>
              )}

            {brokerModel === true ? (
              <ConnectBroker
                uid={userDetails && userDetails._id}
                userDetails={userDetails && userDetails}
                setBrokerStatus={setBrokerStatus}
                setUpdateUserDetails={setUpdateUserDetails}
                setBrokerModel={setBrokerModel}
                getUserDeatils={getUserDeatils}
                broker={broker}
                setBroker={setBroker}
              />
            ) : null}

            {updateUserDetails === true ? (
              <UpdateUserDeatils
                setUpdateUserDetails={setUpdateUserDetails}
                userEmail={userDetails?.email}
                advisorName={process.env.REACT_APP_ADVISOR_SPECIFIC_TAG}
                userDetails={userDetails}
              />
            ) : null}


            {/* IIFL Login  Modal with Mobile Drawer    */}

            {openTokenExpireModel === true ? (
              <TokenExpireModal
                openTokenExpireModel={openTokenExpireModel}
                setOpenTokenExpireModel={setOpenTokenExpireModel}
              >
                <div className="flex flex-col items-center justify-center space-y-2">
                  <div className="flex items-center justify-center rounded-full p-2 ">
                    <Info className="text-[#000000]/50 w-16 h-16 " />
                  </div>
                </div>
                <div className="flex space-x-4 items-center justify-center  text-black text-center text-2xl font-bold mt-4 mb-4">
                  <span className="text-[20px] text-[#000000] font-medium font-poppins text-center">
                    Please login to your broker to continue investments
                  </span>
                </div>
                <div className="md:flex flex-col space-y-2">
                  {broker === "IIFL Securities" ? (
                    <div className="md:flex flex-col space-y-2">
                      <div className={style.inputStartDiv}>
                        <input
                          id="clientCode"
                          placeholder="Client Code"
                          type="text"
                          value={clientCode}
                          disabled
                          className={`${style.inputBox}`}
                        />
                        <label
                          htmlFor="clientCode"
                          className={`${style.firstHeading} ${style.labelFloat}`}
                        >
                          Client Code
                        </label>
                      </div>
                      <div className={style.inputStartDiv}>
                        <input
                          id="my2pin"
                          placeholder="My2Pin"
                          type="text"
                          value={my2pin}
                          disabled
                          className={`${style.inputBox}`}
                        />
                        <label
                          htmlFor="my2pin"
                          className={`${style.firstHeading} ${style.labelFloat}`}
                        >
                          My2Pin
                        </label>
                      </div>
                      <div className={`relative ${style.inputStartDiv} `}>
                        <input
                          id="pass"
                          placeholder="password"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className={`${style.inputBox}`}
                        />
                        <label
                          htmlFor="pass"
                          className={`${style.firstHeading} ${style.labelFloat}`}
                        >
                          Password
                        </label>
                        {showPassword ? (
                          <EyeIcon
                            onClick={() => setShowPassword(false)}
                            className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                          />
                        ) : (
                          <EyeOffIcon
                            onClick={() => setShowPassword(true)}
                            className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                          />
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>

                {broker === "IIFL Securities" ? (
                  <div
                    className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
                    onClick={handleIiflLogin}
                  >
                    {loginLoading === true ? (
                      <LoadingSpinner />
                    ) : (
                      <span className="text-[16px] text-white font-semibold font-poppins">
                        Login to {broker ? broker : ""}
                      </span>
                    )}
                  </div>
                ) : broker === "ICICI Direct" ? (
                  <div className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer">
                    <a
                      href={`https://api.icicidirect.com/apiuser/login?api_key=${encodeURIComponent(
                        checkValidApiAnSecret(apiKey ? apiKey : "")
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="text-[16px] text-white font-semibold font-poppins">
                        Login to {broker ? broker : ""}
                      </span>
                    </a>
                  </div>
                ) : broker === "Upstox" ? (
                  <div className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer">
                    <a
                      href={`https://api.upstox.com/v2/login/authorization/dialog?response_type=code&client_id=${checkValidApiAnSecret(
                        apiKey
                      )}&redirect_uri=${brokerConnectRedirectURL}&state=ccxt`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="text-[16px] text-white font-semibold font-poppins">
                        Login to {broker ? broker : ""}
                      </span>
                    </a>
                  </div>
                ) : (
                  <div className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer">
                    <a
                      href={`https://smartapi.angelbroking.com/publisher-login?api_key=${angelOneApiKey}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="text-[16px] text-white font-semibold font-poppins">
                        Login to {broker ? broker : ""}
                      </span>
                    </a>
                  </div>
                )}
              </TokenExpireModal>
            ) : null}

            {/* Ignore Trade Model with Mobile Drawer */}

            {openIgnoreTradeModel === true ? (
              <IgnoreTradeModal
                openIgnoreTradeModel={openIgnoreTradeModel}
                setOpenIgnoreTradeModel={setOpenIgnoreTradeModel}
                handleIgnoredTrades={handleIgnoredTrades}
                stockIgnoreId={stockIgnoreId}
                ignoreText={ignoreText}
                setIgnoreText={setIgnoreText}
                ignoreLoading={ignoreLoading}
                style={style}
              />
            ) : null}

            {stockDetails.length !== 0 && openReviewTrade === true ? (
              <ReviewTradeModel
                calculateTotalAmount={calculateTotalAmount}
                getLTPForSymbol={getLTPForSymbol}
                stockDetails={stockDetails}
                setStockDetails={setStockDetails}
                setOpenReviewTrade={handleOpenReviewModal}
                placeOrder={placeOrder}
                loading={loading}
                funds={funds?.data?.availablecash}
                openReviewTrade={openReviewTrade}
                getCartAllStocks={getCartAllStocks}
              />
            ) : stockDetails.length !== 0 &&
              broker === "Zerodha" &&
              openZerodhaModel === true ? (
              <ZerodhaReviewModal
                getLTPForSymbol={getLTPForSymbol}
                stockDetails={stockDetails}
                setStockDetails={setStockDetails}
                calculateTotalAmount={calculateTotalAmount}
                funds={funds?.data?.availablecash}
                setOpenZerodhaModel={handleOpenZerodhaModal}
                handleZerodhaRedirect={handleZerodhaRedirect}
                openZerodhaModel={openZerodhaModel}
                getCartAllStocks={getCartAllStocks}
              />
            ) : null}

            {openSuccessModal && (
              <RecommendationSuccessModal
                setOpenSucessModal={setOpenSucessModal}
                orderPlacementResponse={orderPlacementResponse}
                setStockDetails={setStockDetails}
                openSuccessModal={openSuccessModal}
                setSelectedRecommendation={setSelectedRecommendation}
              />
            )}

{showDdpiModal && (
        <DdpiModal
          isOpen={showDdpiModal}
          setIsOpen={handleCloseDdpiModal}
          proceedWithTpin={handleProceedWithTpin}
          userDetails={userDetails && userDetails}
          setOpenReviewTrade={setOpenReviewTrade}
        />
      )}
      {showAngleOneTpinModel && (
        <AngleOneTpinModal
          isOpen={showAngleOneTpinModel}
          setIsOpen={setShowAngleOneTpinModel}
          userDetails={userDetails}
          edisStatus={edisStatus}
          tradingSymbol={stockDetails.map((stock) => stock.tradingSymbol)}
        />
      )}

      {showDhanTpinModel && (
        <DhanTpinModal
          isOpen={showDhanTpinModel}
          setIsOpen={setShowDhanTpinModel}
          userDetails={userDetails}
          dhanEdisStatus={dhanEdisStatus}
          singleStockTypeAndSymbol={singleStockTypeAndSymbol}

        />
      )}

{showOtherBrokerModel && (
        <OtherBrokerModel
          userDetails={userDetails}
          onContinue={() => {
            setIsReturningFromOtherBrokerModal(true);
            setShowOtherBrokerModel(false);
          }}
          setShowOtherBrokerModel={setShowOtherBrokerModel}
         
          setOpenReviewTrade={setOpenReviewTrade}
        />
      )}

          </div>
        </div>
      )}
    </>
  );
};

export default OrderBookDetails;
