import React, { useState, useEffect, useRef } from "react";
import DashboardLayout from "../RootSection/DashboardLayout";
import {
  User2Icon,
  UserIcon,
  UserRoundCogIcon,
  MessageCircle,
  X,
  Send,
  Minimize2,
  Maximize2,
  Bot,
  User,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion"

//bot DAta
const faqData = [
  {
    question: "How do I schedule an appointment?",
    answer:
      "You can schedule an appointment by clicking the 'Book Appointment' button on this page or by emailing your advisor directly.",
  },
  {
    question: "What services do you offer?",
    answer:
      "We provide comprehensive financial advisory services, including stock recommendations, strategy creation, portfolio and history management, and much more to support your investment goals. ",
  },
  {
    question: "How often should I review my portfolio?",
    answer:
      "We recommend reviewing your portfolio at least annually or when there are significant changes in your life circumstances.",
  },
  {
    question: "What are your fees?",
    answer:
      "Our fee structure varies depending on the services provided. Please contact your advisor for a detailed breakdown of our fees.",
  },
  {
    question: "How can I change my investment strategy?",
    answer:
      "To change your investment strategy, please schedule an appointment with your advisor to discuss your goals and risk tolerance.",
  },
];

const ContactAdvisorPage = () => {
  const fullURL = window.location.href;

  //below ones for FAQ bot
  const email = process.env.REACT_APP_EMAIL ?? "hello@alphaquark.in";
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [input, setInput] = useState("");

  const messagesEndRef = useRef(null);

  const [messages, setMessages] = useState([
    {
      text: "Hello! How can I help you today? Please choose a question from the list below:",
      isBot: true,
    },
    {
      text: faqData
        .map((faq, index) => `${index + 1}. ${faq.question}`)
        .join("\n"),
      isBot: true,
    },
  ]);
  // const handleInputChange = (e) => {
  //   setInput(e.target.value);
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (input.trim()) {
  //     setMessages([
  //       ...messages,
  //       { text: input, isBot: false },
  //       {
  //         text: "I'm sorry, I don't have an answer for that specific question. Is there anything else I can help you with?",
  //         isBot: true,
  //       },
  //     ]);
  //     setInput("");
  //   }
  // };

  // const toggleChat = () => setIsOpen(!isOpen);
  // const toggleMinimize = () => setIsMinimized(!isMinimized);

  // const handleQuestionClick = (question, answer) => {
  //   setMessages([
  //     ...messages,
  //     { text: question, isBot: false },
  //     { text: answer, isBot: true },
  //   ]);
  // };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  
  useEffect(() => {
    window.fwSettings = {
      'widget_id': 1070000002103
    }
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://ind-widget.freshworks.com/widgets/1070000002103.js'
    script.async = true
    script.defer = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <DashboardLayout>
      <AnimatePresence mode="wait">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4 }}

          className="flex flex-col items-center justify-center space-y-6 relative  w-full lg:min-h-screen  bg-[#f9f9f9] py-6">
          {fullURL === "https://altqube.alphaquark-case.com/contact-advisor" ? (
            <div className="flex flex-col space-y-6 items-center px-6 lg:w-[700px]">
              <div className="text-center text-[16px] lg:text-[22px] text-[#000000]/60 lg:leading-[30px] font-medium font-poppins">
                You can contact your advisor on{" "}
                <a href="mailto:csingh@altqube.com">csingh@altqube.com</a> or drop
                a message on +917895547770.
              </div>
              <div className="text-center text-[16px] lg:text-[22px] text-[#000000]/60 lg:leading-[30px] font-medium font-poppins">
                Your trusted financial advisor is just an email away !
              </div>
              <a
                target="_blank"
                className="mt-4 px-12 group flex space-x-4 items-center justify-center text-black text-center text-2xl font-bold border-[1px] border-[#000000]/20   py-3 rounded-[6px] hover:bg-black  transition-all duration-150 ease-linear cursor-pointer"
                href="https://topmate.io/chanpreet515"
              >
                <span className="text-black group-hover:text-white text-center text-[18px] font-medium  font-poppins">
                  Book Appointment
                </span>
              </a>
            </div>
          ) : (
            <div className="flex flex-col space-y-3 items-center px-6 lg:w-[700px]">
              <div className="flex items-center justify-center w-[150px] h-[150px] rounded-full bg-gray-200/70 ">
                <UserRoundCogIcon className="w-[70px] h-[70px]" />
              </div>
              <div className="text-center text-[16px] lg:text-[22px] text-[#000000]/60 lg:leading-[30px] font-medium font-poppins">
                You can contact your advisor on{" "}
                <a
                href={`mailto:${email}`}
                target="_blank"
                rel="noreferrer noopener"
                className="font-semibold text-black"
              >
                {email}
              </a>
                {/* <a
                  href="https://calendly.com/gopesh-alphaquark/30min"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="font-semibold text-black"
                >
                  https://calendly.com/gopesh-alphaquark/30min
                </a> */}

                {" "}
                <br />or request to schedule a call
              </div>
              <div className="text-center text-[16px] lg:text-[22px] text-[#000000]/60 lg:leading-[30px] font-medium font-poppins">
                Your trusted financial advisor is just an email away !
              </div>
            </div>
          )}

          {/* <div className="fixed bottom-14 right-4 z-50 font-sans">
          {!isOpen && (
            <button
              onClick={toggleChat}
              className="bg-black text-white p-3 rounded-full shadow-lg cursor-pointer transition-colors"
              aria-label="Open FAQ"
            >
              <MessageCircle size={24} />
            </button>
          )}

          {isOpen && (
            <div
              className={`bg-white rounded-lg shadow-xl w-80 ${
                isMinimized ? "h-12" : "h-[32rem]"
              } flex flex-col transition-all duration-300`}
            >
              <div className="flex justify-between items-center p-3 bg-black text-white rounded-t-lg">
                <h2 className="text-lg font-semibold">FAQ Bot</h2>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={toggleMinimize}
                    className="text-white hover:text-gray-200 focus:outline-none"
                  >
                    {isMinimized ? (
                      <Maximize2 size={18} />
                    ) : (
                      <Minimize2 size={18} />
                    )}
                  </button>
                  <button
                    onClick={toggleChat}
                    className="text-white hover:text-gray-200 focus:outline-none"
                  >
                    <X size={18} />
                  </button>
                </div>
              </div>

              {!isMinimized && (
                <>
                  <div className="flex-1 overflow-y-auto no-scrollbar p-4 space-y-4">
                    {messages.map((message, index) => (
                      <div
                        key={index}
                        className={`flex ${
                          message.isBot ? "justify-start" : "justify-end"
                        }`}
                      >
                        {message.isBot && (
                          <Bot className="h-6 w-6 mr-2 text-primary" />
                        )}

                        <div
                          className={`max-w-[90%] text-sm font-poppins p-2 rounded-lg ${
                            message.isBot
                              ? "bg-gray-100 text-gray-800"
                              : "bg-black text-white"
                          }`}
                        >
                          {message.text}
                        </div>
                        {!message.isBot && (
                          <User className="h-6 w-6 ml-2 text-primary" />
                        )}
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </div>

                  <div className="p-4 border-t border-gray-200">
                    <div className="text-sm font-medium mb-2 text-gray-700">
                      Choose a question:
                    </div>
                    <div className="space-y-2 max-h-28 p-1 overflow-y-auto custom-scroll">
                      {faqData.map((faq, index) => (
                        <button
                          key={index}
                          className="w-full text-left px-3 py-2 text-sm bg-gray-100 hover:bg-gray-200 rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-black"
                          onClick={() =>
                            handleQuestionClick(faq.question, faq.answer)
                          }
                        >
                          {faq.question}
                        </button>
                      ))}
                    </div>
                  </div>

                  <form
                    onSubmit={handleSubmit}
                    className="p-4 border-t border-gray-200"
                  >
                    <div className="flex items-center space-x-2">
                      <input
                        type="text"
                        value={input}
                        onChange={handleInputChange}
                        placeholder="Type your question..."
                        className="flex-1 p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                      />
                      <button
                        type="submit"
                        className="p-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-black"
                      >
                        <Send size={15} />
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
          )}
        </div> */}
        </motion.div>
      </AnimatePresence>
    </DashboardLayout>
  );
};

export default ContactAdvisorPage;
