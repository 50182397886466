import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";

import Rightsection1 from "./assests/process_section/rightSection1.png";
import Rightsection2 from "./assests/process_section/rightSection2.png";
import Rightsection3 from "./assests/process_section/rightSection3.png";
import Rightsection4 from "./assests/process_section/rightSection4.png";
import Rightsection5 from "./assests/process_section/rightSection5.png";

const steps = [
  {
    title: "SUBSCRIBE TO A MODEL PORTFOLIO",
    content: "Choose a model portfolio aligned with your investment goals. Subscribing provides access to expertly curated stock strategies tailored for growth, stability, or income.",
  },
  {
    title: "CONNECT YOUR BROKER",
    content: "Link your brokerage account securely to execute trades directly. This ensures a seamless trading experience and real-time updates on portfolio actions.",
  },
  {
    title: "Review & Place Trades",
    content: "Preview the recommended trades before confirming. This allows you to understand the changes being made and align them with your preferences.",
  },
  {
    title: "Order Status Confirmation",
    content: "Receive real-time updates on the status of your orders. Stay informed about executed trades and any potential issues that may arise during the process.",
  },
  {
    title: "Rebalance Alerts",
    content: "Rebalancing keeps your portfolio in line with the strategy, optimizing performance and managing risk by buying new stocks and selling old ones.",
  },
];

const ProcessSection = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const animationRef = useRef(null);
  const stepTitleClass = "transition-colors duration-300 ease-in-out";

  useEffect(() => {
    const animate = () => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 0.1;
        
        if (newProgress >= 100) {
          setActiveStep(0);
          return 0;
        }
        
        const newStep = Math.floor(newProgress / 25);
        if (newStep !== activeStep) {
          setActiveStep(newStep);
        }
        
        return newProgress;
      });
      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [activeStep]);

  const getCircleColor = (index) => {
    return progress > index * 25 ? "#4CC7B1" : "#ffffff";
  };

  const getTextColor = (index) => {
    return progress > index * 25 ? "#ffffff" : "#4B5563";
  };

  const rightSectionImage = [
    Rightsection1,
    Rightsection2,
    Rightsection3,
    Rightsection4,
    Rightsection5,
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 py-8 md:py-16 h-[170vh] sm:h-[130vh]">
      <h1 className="text-[28px] md:text-[3rem] font-bold text-center mb-8 md:mb-12">
        How to Invest ?
      </h1>

      <div className="flex justify-center mb-8 md:mb-16">
        <div className="border-b border-gray-200 w-full max-w-2xl">
          <div className="flex justify-center space-x-4 md:space-x-8">
            <button className="relative text-black pb-4 border-b-2 border-black font-medium text-sm md:text-base">
              Model Portfolio Plan
            </button>
            <button className="relative text-gray-500 pb-4 border-b-2 border-transparent font-medium text-sm md:text-base">
              Stock Tips Plan
            </button>
          </div>
        </div>
      </div>

      <div className="relative px-4 max-w-6xl mx-auto mb-12 md:mb-20">
        {/* Mobile View */}
        <div className="block md:hidden">
          <div className="relative">
            <div className="absolute left-4 top-0 bottom-0 w-[2px] bg-gray-200">
              <motion.div 
                className="w-[2px] bg-[#4CC7B1]" 
                initial={{ height: "0%" }}
                animate={{ height: `${progress}%` }}
                transition={{ duration: 0.5 }}
              />
            </div>

            <div className="space-y-12">
              {steps.map((step, index) => (
                <div key={index} className="flex items-start ml-0">
                  <motion.div 
                    className="w-8 h-8 rounded-full flex items-center justify-center font-medium z-10 border-2 border-gray-300"
                    animate={{
                      backgroundColor: getCircleColor(index),
                      color: getTextColor(index),
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    {index + 1}
                  </motion.div>
                  <span className={`ml-4 text-sm font-poppins font-medium pt-1 ${stepTitleClass}`}>
                    {step.title}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Desktop View */}
        <div className="hidden md:block " >
          <div className="flex justify-between items-center relative">
            <div className="absolute top-5 left-5 right-5 flex items-center">
              <motion.div
                className="h-[2px] bg-[#4CC7B1]"
                initial={{ width: "0%" }}
                animate={{ width: `${progress}%` }}
                transition={{ duration: 0.2 }}
              />
              <div className="h-[2px] bg-gray-200 flex-grow" />
            </div>

            <div className="flex justify-between w-full relative">
              {steps.map((step, index) => (
                <div key={index} className="flex flex-col items-center">
                  <motion.div
                    className="w-10 h-10 rounded-full flex items-center justify-center font-medium z-10 border-2 border-gray-300"
                    animate={{
                      backgroundColor: getCircleColor(index),
                      color: getTextColor(index),
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    {index + 1}
                  </motion.div>
                  <span className={`absolute w-full top-14 text-sm text-center font-poppins font-medium ${stepTitleClass}`}>
                    {step.title}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={activeStep}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col md:flex-row justify-center items-center gap-6 md:gap-8 sm:h-[60vh]"
        >
          <div className="w-full md:w-1/2 max-w-md ">
            <h2 className="text-xl md:text-2xl font-poppins font-semibold mb-3 md:mb-4">
              {steps[activeStep].title}
            </h2>
            <p className="text-black text-sm md:text-base font-poppins mb-6 md:mb-8">
              {steps[activeStep].content}
            </p>
            <button className="bg-black text-white px-8 md:px-12 py-2.5 md:py-3 rounded-md font-medium hover:bg-black/90 transition-colors text-sm md:text-base">
              Get Started
            </button>
          </div>
          <div className="w-full md:w-1/2 flex justify-center mt-10 sm:mt-20">
            <img
              src={rightSectionImage[activeStep]}
              alt="Right section"
              className="max-w-full h-auto"
            />
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default ProcessSection;

