import React from "react";
import Angleone from "../assests/AngleLogo.png";
import IIFL from "../assests/iifl_logo.png";
import Zerodha from "../assests/Zerodha.png";
import ICICI from "../assests/icici.png";
import Upstox from "../assests/upstox.svg";
import Kotak from "../assests/kotak.png";
import HDFC from "../assests/hdfc_securities.png";
import Dhan from "../assests/dhan.png";
import AliceBlue from "../assests/aliceblue.png";
import Fyers from "../assests/fyers.png";
import Bg from './assests/broker/bg.png'
import Insights from "./Insights";
import Brokers from "./assests/broker/allBrokers.png"

const BrokerSection = () => {
  const brokers = [
    { img: Angleone, name: "Angel One" },
    { img: Zerodha, name: "Zerodha" },
    { img: Kotak, name: "Kotak" },
    { img: ICICI, name: "ICICI Direct" },
    { img: IIFL, name: "IIFL" },
    { img: Upstox, name: "Upstox" },
    { img: Dhan, name: "Dhan" },
    { img: AliceBlue, name: "Alice Blue" },
    { img: Fyers, name: "Fyers" },
    { img: HDFC, name: "HDFC Securities" }
  ];

  return (
    <>
    <section 
      className="relative py-32 overflow-hidden"
      style={{
        background: `url(${Bg}) no-repeat center center`,
        backgroundSize: 'cover'
      }}
    >
      <div className="absolute inset-0 " />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          {/* Left Content */}
          <div className="text-white space-y-5">
            <h3 className="text-lg font-poppins font-medium text-gray-400 tracking-wide">SUPPORTED BROKERS</h3>
            <h2 className="text-3xl lg:text-5xl font-semibold font-poppins leading-tight">
              Seamless Integration <br/>with Top Brokers for <br/>  Effortless Trading
            </h2>
            <div className="pt-4">
              <button className="bg-[#55C2C3] font-poppins text-white w-[250px] py-4 rounded-xl text-lg font-semibold hover:bg-[#55C2C3]/90 transition-colors">
                Invest Now
              </button>
            </div>
          </div>

          {/* Right Grid */}
          {/* <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
            {brokers.map((broker) => (
              <div 
                key={broker.name}
                className="bg-white rounded-lg px-4 py-3 flex items-center space-x-3 transform hover:scale-105 transition-transform"
              >
                <div className="h-8 w-8 flex items-center justify-center">
                  <img 
                    src={broker.img} 
                    alt={`${broker.name} logo`}
                    className="max-h-full max-w-full object-contain"
                  />
                </div>
                <span className="text-base font-semibold text-gray-700">{broker.name}</span>
              </div>
            ))}
          </div> */}
          <img src={Brokers} className="w-full"/>
        </div>
      </div>
    </section>

<Insights/>
</>
  );
};

export default BrokerSection;

